import { KEYS } from "./KeyCodes"

export const Notes = {
    [KEYS.A]: "C4",
    [KEYS.S]: "D4",
    [KEYS.D]: "E4",
    [KEYS.F]: "F4",
    [KEYS.G]: "G4",
    [KEYS.H]: "A4",
    [KEYS.J]: "B4",
    [KEYS.K]: "C5",
    [KEYS.L]: "D5"
};